<template>
  <div class="page page--inner">
    <mainBlock :type="'reset'"/>
  </div>
</template>

<script>
  import mainBlock from '@/views/main/mainBlock/mainBlock'

  export default {
    name: 'resetPasswordLayout',
    components: {
      mainBlock,
    },
  }
</script>
